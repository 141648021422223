import React from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { colors } from 'config/colors';

export const SecondaryNav = ({ secNavText, navToURL,state }) => {
  const t = useIntl();
  let navigate = useNavigate();

  let { handleTransition } = useRouterTransition(navigate);

  const navTo = (url) =>{
    handleTransition (url, state)
  };

  return (
    <Row className="my-3">
      <Col xs="8" md="9" className="d-flex align-items-center justify-content-start">
        <span 
          style={{ 
            fontSize: '3.2vw', 
            fontWeight: '600' 
          }}
        >
          {t.messages[`${secNavText}`]}
        </span>
      </Col>
      <Col xs="4" md="3" className="d-flex align-items-center justify-content-end">
        <button 
          onClick={() => navTo(`${navToURL}`)} 
          className="w-100 py-1" 
          style={{ 
            backgroundColor: colors[process.env.REACT_APP_ZONE].medium,
            borderRadius: 0,
            border: 'none', 
            color: colors.white,
            fontSize: '3.0vw',
            fontWeight: '600',
          }}
        >
          {t.messages['app.goBack']}
        </button>
      </Col>
    </Row>
  );
};