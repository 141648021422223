export const routes = [
  { 
    path: "yellow-zone", 
    label: "route.yellow-zone",
    inativityTime: false
  },
  { 
    path: "green-zone", 
    label: "route.green-zone", 
    inativityTime: false
  },
  { 
    path: "red-zone", 
    label: "route.red-zone", 
    inativityTime: false
  },
  { 
    path: "lokker-type",
    label: "route.request-lokker",
    inativityTime: 30
  },
  { 
    path: "lokker-size",
    label: "route.request-lokker",
    inativityTime: 30
  },
  { 
    path: "lokker-assigned",
    label: "route.lokker-assigned",
    inativityTime: false
  },
  { 
    path: "create-pin",
    label: "route.request-lokker",
    inativityTime: 30
  },
  { 
    path: "accessible-lokker",
    label: "route.request-lokker",
    inativityTime: 25
  },
  { 
    path: "access-my-lokkers",
    label: "route.my-lokkers",
    inativityTime: false
  },
  { 
    path: "my-lokkers",
    label: "route.my-lokkers",
    inativityTime: false
  },
  { 
    path: "release-lokker",
    label: "route.my-lokkers",
    inativityTime: false
  },
  { 
    path: "lokker-released",
    label: "route.my-lokkers",
    inativityTime: false
  },
  { 
    path: "lokker-opened",
    label: "route.my-lokkers",
    inativityTime: false
  },
  { 
    path: "pick-and-collect",
    label: "route.pickNCollect",
    inativityTime: false
  },
  { 
    path: "deposit-size",
    label: "route.depositObject",
    inativityTime: false
  },
  { 
    path: "deposit-create-pin",
    label: "route.depositObject",
    inativityTime: false
  },
  { 
    path: "deposit-confirm",
    label: "route.depositObject",
    inativityTime: false
  },
  { 
    path: "deposit-lokker-opened",
    label: "route.depositObject",
    inativityTime: false
  },
  { 
    path: "collect-access-pin",
    label: "route.collectObject",
    inativityTime: false
  },
  { 
    path: "collect-object",
    label: "route.collectObject",
    inativityTime: false
  },
  { 
    path: "recover-pin",
    label: "route.recoverPin",
    inativityTime: 25
  },
  { 
    path: "sms-sent",
    label: "route.recoverPin",
    inativityTime: 10
  },
  { 
    path: "terms-conditions",
    label: "route.terms-conditions",
    inativityTime: 30
  }
];

export const routerTransitionDelay = 1;
export const mobileBreakPoint = 768;
export const disabledOpacity = '0.3';
export const borderWidth = '8px';
export const borderWidthMobile = '2px';

export const backgroundImageExclusions = ['access-my-lokkers', 'create-pin', 'recover-pin', 'terms-conditions'];
