import React, { useEffect } from 'react';
import { MenuButton } from 'components/common/MenuButton';
import { Row, Col } from 'reactstrap';
import { useNavigate, useParams } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { ReactComponent as Bag } from 'assets/images/icons/v2/Bag.svg';
import { useDispatch } from 'react-redux';

const styles= {
  row: {
    height: '40%'
  },
  middleRow: {
    height: '20%',
    fontSize: '3vw'
  },
  boldHintText: {
    fontWeight: '600',
    marginBottom: '-10vw'
  }
};

export const RedZoneHome = () => {
  const t = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const { urlToken } = useParams();

  const navTo = (url, state) => {
    handleTransition (url, state)
  };

  useEffect(() => {
    dispatch({type: 'SET_URL_TOKEN', payload: urlToken })
    dispatch({type: 'SET_HOMEPAGE', payload: true })
  },[]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType}>
      <Row style={styles.row} className="h-100 align-items-center px-5">
        <Col>
          <Row>
            <MenuButton
              text={t.messages['app.requestLocker']}
              subtext={t.messages['app.requestLockerSub']}
              icon={<Bag height="30" width="30" />}
              onClick={() => navTo('/terms-conditions', { state: { type: 0, accessibility: 0 } })}
            />
          </Row>
          <Row className="py-3">
            <MenuButton
              text={t.messages['app.myLockers']}
              subtext={t.messages['app.myLockersSub']}
              icon={<Bag height="30" width="30"/>}
              onClick={() => navTo('/access-my-lokkers')}
            />
          </Row>
        </Col>
      </Row>
    </TransitionView>
  );
};