import React from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as LockerSmall } from 'assets/images/icons/v2/LockerSmall.svg';
import { ReactComponent as LockerMedium } from 'assets/images/icons/v2/LockerMedium.svg';
import { ReactComponent as LockerLarge } from 'assets/images/icons/v2/LockerLarge.svg';
import { disabledOpacity } from 'config/appConfig';
import { SecondaryNav } from 'components/common/SecondaryNav';

export const RedZoneLokkerSize = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();
 
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  
  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const normalSizeSLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 &&
        locker.lockerType === state.type &&
        locker.size === 'S' &&
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeMLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'M' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeLLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'L' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  };

  const normalSizeXlLockersAvailable = () => {
    return state.lockers.filter(function (locker) {
      return (
        locker.enabled === 1 && 
        locker.status === 0 && 
        locker.locker_metra_status === 2 && 
        locker.lockerType === state.type && 
        locker.size === 'XL' && 
        ( state.accessibility === 0 ? (locker.accessibility === 0 || locker.accessibility === 1) : locker.accessibility === 1 )
      )
    });
  }

  return(
    state && state.checked &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-3">
      <SecondaryNav secNavText="app.pickLockerSize" navToURL={state.accessibility ? '/accessible-lokker' : '/lokker-type' } state={{ state: {...state } }}/>
      <Row className="m-0 align-items-center">
        <MenuButton
          text={t.messages['app.smallLocker']}
          subtext={`${normalSizeSLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="align-items-end">
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerSmall height="15" width="15" style={{ opacity: '1' }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerMedium height="25" width="25" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="35" width="35" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="45" width="45" style={{ opacity: disabledOpacity }} /></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'S'}})}
          disabled={normalSizeSLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0 my-1">
        <MenuButton
          text={t.messages['app.mediumLocker']}
          subtext={`${normalSizeMLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="align-items-end">
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerSmall height="15" width="15" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerMedium height="25" width="25" style={{ opacity: '1' }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="35" width="35" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="45" width="45" style={{ opacity: disabledOpacity }} /></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'M'}})}
          disabled={normalSizeMLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.largeLocker']}
          subtext={`${normalSizeLLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="align-items-end">
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerSmall height="15" width="15" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerMedium height="25" width="25" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="35" width="35" style={{ opacity: '1' }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="45" width="45" style={{ opacity: disabledOpacity }} /></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'L'}})}
          disabled={normalSizeLLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.extraLargeLocker']}
          subtext={`${normalSizeXlLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={
            <Row className="align-items-end">
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerSmall height="15" width="15" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerMedium height="25" width="25" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="35" width="35" style={{ opacity: disabledOpacity }} /></Col>
              <Col className="p-0 d-flex justify-content-center" xs="3"><LockerLarge height="45" width="45" style={{ opacity: '1' }} /></Col>
            </Row>
          }
          onClick={() => navTo('/create-pin', { state : { ...state, size: 'XL'}})}
          disabled={normalSizeXlLockersAvailable().length !== 0 ? false : true}
        />
      </Row>
    </TransitionView>
  )
}