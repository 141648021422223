import React, { useEffect } from 'react';
import { AppRouter } from './router';
import { GlobalDebug } from "helpers/utils";
import { useDispatch } from "react-redux";

import './App.css';
import 'assets/css/animations.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const dispatch = useDispatch()
  
  useEffect(() => {
    (process.env.REACT_APP_ENV === "prod") &&
      GlobalDebug(false);
  }, []);

  const updateWidth = () => {
    dispatch({type: 'UPDATE_VERSION', payload: window.innerWidth })
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth()
    return () => window.removeEventListener("resize", updateWidth);
  });
  
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
