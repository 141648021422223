import { mobileBreakPoint } from 'config/appConfig';

const layoutReducer = (state = { mobile : '', homepage : false }, action) => {
  switch(action.type){
    case 'UPDATE_VERSION':
      if(action.payload > mobileBreakPoint){
        return { ...state, mobile: false }
      }else{
        return { ...state, mobile: true }
      }
    case 'SET_HOMEPAGE':
      return { ...state, homepage: action.payload }
    default:
       return state;
  }
};

export default layoutReducer;