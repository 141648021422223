import api from "helpers/api"

export const getFaqs = () => (dispatch) =>
new Promise(function(resolve, reject){
    api.get('api/faqs/list')
    .then(response => {
      if (response.data.ResultCode === 1) {
          dispatch({
            type : 'GET_FAQS_SUCCESS',
            payload : response.data.Data
          })
        resolve(response)
      } else {
        reject(response)
        throw(response);
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})
