const tokenReducer = (state = { token : '', urlToken : '' }, action) => {
  switch(action.type){
    case 'REFRESH_TOKEN':
      return { ...state, token: action.payload }
    case 'SET_URL_TOKEN':
      return { ...state, urlToken: action.payload }
    default:
      return state;
  }
};

export default tokenReducer;