import languageReducer from "./language";
import layoutReducer from "./layout";
import tokenReducer from "./token";
import faqsReducer from "./faqs";
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  language : languageReducer,
  layout : layoutReducer,
  credentials : tokenReducer,
  faqs : faqsReducer,
});

export default rootReducer;