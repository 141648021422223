import React, { useState } from 'react'
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { ReactComponent as ChevronDown } from 'assets/images/icons/v2/ChevronDown.svg';
import { countryCodes } from 'config/numberPrefix';

export const DropdownLanguage = ({ setPhoneNumberSize, setPhoneNumber, setSelectedInput, selectedCountry, setSelectedCountry }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const changeCountry = (country) => {
    setSelectedCountry(country)
    setPhoneNumber('')
    setPhoneNumberSize(country.characters)
    setSelectedInput('phoneNumber')
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="">
      <DropdownToggle color="white" className="">
        <Row className="d-flex align-items-center">
          <Col xs="6" md="6" className="px-1">
            {selectedCountry.flag}
          </Col>
          <Col xs="6" md="6" className="px-1" style={{ transform: !dropdownOpen ? 'rotate(-90deg)' : '',  }}>
            <ChevronDown height="25" width="25"/>
          </Col>
        </Row>
      </DropdownToggle>
      <DropdownMenu>
        {countryCodes.map((item, index) =>
          selectedCountry.code !== item.code &&
            <DropdownItem key={index} onClick={() => changeCountry(item)}>
              <Row className="d-flex align-items-center">
                <Col xs="6" md="6" className="">
                  {item.flag}
                </Col>
                <Col xs="6" md="6" className="">
                  <span style={{ fontSize: '1.3em', fontWeight: '600' }}>
                    {item.code}
                  </span>
                </Col>
              </Row>
            </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}