export const colors = {
  error: '#ff0000',
  black: '#000000',
  white: '#ffffff',
  'text': {
    primary: '#767571',
    disabled: '#D6D5D4',
  },
  'beije': {
    light: '#F6F4EE',
    medium: '#D7CFC6',
  },
  'grey': {
    light: '#767571',
    medium: '#474747',
  },
  'red-zone': {
    light: '#F9D2CB',
    medium: '#981F25',
  },
  'green-zone': {
    light: '#DFECE4',
    medium: '#8EBDA7',
  },
  'blue-zone': {
    light: '#005CA9',
    medium: '#CBDBEE',
  },
  'yellow-zone': {
    light: '#FFF7B2',
    medium: '#FFE500',
  }
}