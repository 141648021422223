import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { borderWidthMobile } from 'config/appConfig';
import { FormattedMessage } from 'react-intl';

export const OpenReleaseButtons = ({ releaseLokker, openLokker, disabled }) => {

  const styles = {
    leftColumn: {
      padding: '0 0 0 0'
    },
    rightColumn: {
      padding: '0 0 0 0'
    },
    paddingButton: {
      padding: '2.0vw 0 2.0vw 0'
    },
    secondaryButton: {
      backgroundColor: colors.white,
      border: `${borderWidthMobile} solid ${colors[process.env.REACT_APP_ZONE].medium}`,
    },
    primaryButton: {
      backgroundColor: disabled === true ? colors['text'].disabled : colors[process.env.REACT_APP_ZONE].medium,
      border: `${borderWidthMobile} solid ${colors[process.env.REACT_APP_ZONE].medium}`,
    },
    buttonTextLeft: {
      fontSize: '3vw',
      fontWeight: '600'
    },
    buttonTextRight: {
      fontSize: '3vw',
      fontWeight: '600',
      color: colors.white
    }
  }

  return(
    <Row className="mt-3 m-0 p-0">
      <Col xs="6" style={styles.leftColumn}>
        <div style={{...styles.secondaryButton, ...styles.paddingButton}} onClick={releaseLokker}>
          <span style={styles.buttonTextLeft}>
            <FormattedMessage id="app.release" />
          </span>
        </div>
      </Col>
      <Col xs="6" style={styles.rightColumn}>
        <div style={{...styles.primaryButton, ...styles.paddingButton}} onClick={openLokker}>
          <span style={styles.buttonTextRight}>
            <FormattedMessage id="app.openLabel" />
          </span>
        </div>
      </Col>
    </Row>
  );
};