import React from 'react';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { colors } from 'config/colors';

export const RedZoneSmsSent = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition} = useRouterTransition(navigate);

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-center px-5">
      <Row className="m-0">
        <span className="py-5 mb-2" style={{ fontSize: '3.2vw', fontWeight: '600' }}>
          {t.messages['app.smsSentSuccess']}
        </span>
        <PrimaryButton txtColor="light" text={t.messages['app.goBackToMenu']} color="white" border={colors['grey'].light} onClick={() => navTo('/')} />
      </Row>
    </TransitionView>
  );
};