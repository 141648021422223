import React from "react";
import { ReactComponent as Lokk } from "assets/images/logo/Lokk.svg";

export const LoadingModal = ({ isLoading }) => {
  const styles = {
    container: {
      position: "fixed",
      width: "100%",
      height: "100%",
      display: "flex",
      top: 0,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(250,250,250, 0.93)",
      zIndex: 15,
    },
  };

  return (
    isLoading && (
      <div style={styles.container}>
        <svg
          className="spinner my-auto p-0"
          width="80px"
          height="80px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path-spinner"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
        <Lokk className="position-absolute" style={{ width: "40px" }} />
      </div>
    )
  );
};
