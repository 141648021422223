import React from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { DropdownLanguage } from 'components/common/DropdownLanguage';
import { countryCodes } from 'config/numberPrefix';
import { useIntl } from 'react-intl';

const SelectCountryPrefix = ({ setPhoneNumberSize, setPhoneNumber, setSelectedInput, selectedCountry, setSelectedCountry }) => {
  const t = useIntl();

  return (
    <Row>
      <Col xs="12">
        <span style={{ fontSize: '3vw', fontWeight: '600' }}>
          {t.messages['app.internationalCode']}
        </span>
      </Col>
      <Col xs="12" className="py-0 align-items-center justify-content-center">
        <Row className="py-1 align-items-center justify-content-start">
          <Col xs="6" md="6" className="d-flex justify-content-end align-items-center px-0 py-0">
            <DropdownLanguage
              setPhoneNumberSize={setPhoneNumberSize}
              setPhoneNumber={setPhoneNumber}
              setSelectedInput={setSelectedInput}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </Col>
          <Col xs="6" md="6" className="d-flex justify-content-start align-items-center">
            {countryCodes.map((item, index) =>
              selectedCountry.code === item.code &&
              <span key={index} style={{ fontSize: '3vw', fontWeight: '600' }}>
                {item.dial_code}
              </span>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SelectCountryPrefix;