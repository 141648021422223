import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { PrimaryButtonIcon } from 'components/common/PrimaryButtonIcon';
import { ReactComponent as AcessoFacil } from 'assets/images/icons/v1/AcessoFacil.svg';
import { ReactComponent as Bag } from 'assets/images/icons/v2/Bag.svg';
import { ReactComponent as ThermalCold } from 'assets/images/icons/v2/ThermalCold.svg';
import { ReactComponent as ThermalHot } from 'assets/images/icons/v2/ThermalHot.svg';
import api from 'helpers/api';
import { disabledOpacity } from 'config/appConfig';
import { LoadingModal } from 'components/common/LoadingModal';
import { SecondaryNav } from 'components/common/SecondaryNav';

export const RedZoneAccessibleLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const { state } = useLocation();
  const [iconWidth, setIconWidth] = useState('25')

  const [loading, setLoading] = useState(true);
  const [lockers, setLockers] = useState('');

  const normalLockersAvailable = () => {
    return Object.keys(lockers).filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].lockerType === 0 && lockers[key].accessibility === 1;
    });
  }

  const coldLockersAvailable = () => {
    return Object.keys(lockers).filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].lockerType === 1 && lockers[key].accessibility === 1;
    });
  }

  const hotLockersAvailable = () => {
    return Object.keys(lockers).filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].lockerType === 2 && lockers[key].accessibility === 1;
    });
  }

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  useEffect(() => {
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    state && state.checked &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-3">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText="app.pickLockerType" navToURL="/lokker-type" state={{ state: {...state } }}/>
        <Row className="m-0">
          <MenuButton
            text={t.messages['app.lockerTypeNormalAccessible']}
            subtext={`${normalLockersAvailable().length} ${t.messages['app.availableLockers']}`}
            icon={
              <Row className="px-3">
                <Col md="6">
                  <Bag width={iconWidth} height={iconWidth} style={{ opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
                </Col>
              </Row>
            }
            onClick={() => navTo('/lokker-size', { state: { lockers: lockers, type: 0, accessibility: 1, checked: state.checked } })}
            disabled={normalLockersAvailable().length !== 0 ? false : true}
          />
        </Row>
        <Row className="m-0">
          <MenuButton
            text={t.messages['app.lockerTypeColdAccessible']}
            subtext={`${coldLockersAvailable().length} ${t.messages['app.availableLockers']}`}
            icon={
              <Row className="px-3">
                <Col xs="6">
                  <ThermalHot width={iconWidth} height={iconWidth} style={{ opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
                </Col>
                <Col xs="6">
                  <ThermalCold width={iconWidth} height={iconWidth} style={{ opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
                </Col>
            </Row>
            }
            onClick={() => navTo('/create-pin', { state: { lockers: lockers, type: 1, accessibility: 1, size: 'M' } })}
            disabled={coldLockersAvailable().length !== 0 ? false : true}
          />
        </Row>
        {/* <Row className="m-0">
          <MenuButton
            text={t.messages['app.lockerTypeHotAccessible']}
            subtext={`${hotLockersAvailable().length} ${t.messages['app.availableLockers']}`}
            icon={
              <Row className="px-3">
                <Col xs="12">
                  <ThermalHot width={iconWidth} height={iconWidth} style={{ opacity: hotLockersAvailable().length !== 0 ? '1' : disabledOpacity }}/>
                </Col>
            </Row>
            }
            onClick={() => navTo('/create-pin', { state: { lockers: lockers, type: 2, accessibility: 1, size: 'M' } })}
            disabled={hotLockersAvailable().length !== 0 ? false : true}
          />
        </Row> */}
        <Row className="h-100 pb-4 m-0 align-items-end">
          <div className="m-0 p-0">
            <PrimaryButtonIcon
              text={t.messages['app.exitAccessibleLockers']}
              onClick={() => navTo('/lokker-type', { state: {...state } })}
              icon={<AcessoFacil height={iconWidth} />}
            />
          </div>
        </Row>
    </TransitionView>
  )
}