import React from 'react';
import { Container } from 'reactstrap';
import { routerTransitionDelay } from 'config/appConfig';

import './animations.css';

export const TransitionView = ({ children, animation, transitionType, className }) => {
  
  const addTransitionToElement = (element, index) => {
    const { type, props } = element;

    let newProps = {
      ...props,
      key: index,
      style: {
        ...props.style,
        opacity: `${transitionType === 'in' ? '100%' : '0%'}`,
        animation: `${animation}-${transitionType} 0.3s ease-in-out`,
        transition: `0.3s ease-in-out`,
      }
    };

    const animatedElement = React.createElement(
      type,
      newProps,
      props.children
    );

    return animatedElement;
  };

  if(children.length > 1) return (
    <Container fluid className={`h-100 p-0 d-flex flex-column ${className}`}>
      {children.filter(item => item).map((item, i) => {
        if(!item.props) {
          return item.map((subitem, i2) => 
            addTransitionToElement(subitem, i2)
        )
        }else {
          return addTransitionToElement(item, i)
        }
      })}
    </Container>
  );
  else return <Container fluid className={`h-100 p-0 d-flex flex-column ${className}`}>{addTransitionToElement(children, 1)}</Container>;
};