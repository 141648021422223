import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { disabledOpacity, borderWidthMobile } from 'config/appConfig';

export const MenuButton = ({ text, subtext, icon, onClick, disabled = false, noBackground, noBorder, borderColor }) => {

  const styles = {
    container: {
      border: noBorder ? 'none' : `${borderWidthMobile} solid ${borderColor ? borderColor : colors[process.env.REACT_APP_ZONE].medium}`,
      backgroundColor: noBackground ? 'transparent' : `${disabled !== true ? '#fff' : colors['text'].disabled}`,
    },
    text: {
      fontSize: '3.5vw',
      fontWeight: '600',
      margin: '10px 10px 0px 10px',
      opacity: `${disabled !== true ? '1' : disabledOpacity}`
    },
    subtext: {
      textAlign: "left",
      fontSize: '3.5vw',
      margin: '5px 10px 10px 10px',
      opacity: `${disabled !== true ? '1' : disabledOpacity}`
    }
  }

  return(
    <Row className="my-1 py-1 mx-0 px-1" style={styles.container} onClick={disabled === true ? () => null : onClick}>
      <Col xs="8" className="d-flex flex-column align-items-start">
        <span style={styles.text} >
          {text}
        </span>
        <span style={styles.subtext}>
          {subtext}
        </span>
      </Col>
      <Col xs="4" className="d-flex justify-content-end align-items-center">
        {icon}
      </Col>
    </Row>
  )
}