import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { ReactComponent as WarningIcon } from 'assets/images/icons/v1/WarningIcon.svg';
import { useIntl } from 'react-intl';

const styles = {
  container: {
    backgroundColor: 'transparent',
    paddingTop: '1.5vw'
  },
  headerText: {
    fontSize: '3.5vw',
    fontWeight: '600'
  }, 
  paragraphsText: {
    fontSize: '3.5vw',
    fontWeight: '600',
  },
  footerText: {
    fontSize: '3vw'
  }
}

export const MessageBox = ({ lokkerId }) => {
  const t = useIntl();

const getZone = (zone) => {
    switch (zone) {
      case 'red-zone':
        return t.messages['route.red-zone']
      case 'green-zone':
        return t.messages['route.green-zone']
      default:
        break;
    }
  }

  return (
    <div style={styles.container}>
      <Row className="justify-content-center py-1">
        <WarningIcon />
      </Row>

      <Row className="justify-content-center py-1">
        <Col xs="11">
          <span style={styles.headerText}>
            <FormattedMessage id="app.makeSure" />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-1">
        <Col xs="11">
          <span style={styles.paragraphsText}>
            <FormattedMessage id="app.youAreIn" values={{ lokkerId: lokkerId, zone: getZone(process.env.REACT_APP_ZONE) }} />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-1">
        <Col xs="11">
          <span style={styles.paragraphsText}>
            <FormattedMessage id="app.dontLeave" />
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-1">
        <Col xs="11">
          <span style={styles.footerText}>
            <FormattedMessage id="app.weAreNotResponsible" />
          </span>
        </Col>
      </Row>
      
    </div>
  );
};