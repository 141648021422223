import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { colors } from 'config/colors';
import { countryCodes } from 'config/numberPrefix';
import SelectCountryPrefix from 'components/common/SelectCountryPrefix';
import PinInput from "react-pin-input";

export const RedZoneCreatePin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { state } = useLocation();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinAnimation, setPinAnimation] = useState('');
  const [confirmPinAnimation, setConfirmPinAnimation] = useState('');
  const [phoneAlreadyInUse, setPhoneAlreadyInUse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorNumberPrefix, setErrorNumberPrefix] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumberSize, setPhoneNumberSize] = useState(selectedCountry.characters);
  const [errorCredentials, setErrorCredentials] = useState(false);

  const numbers = ['1', '2', '3', '6'];

  const pinSize = 4;
  const invalidInputAnimationDuration = 0.7;

  const handleNumberChangeMobile = (value) => {
    if (value.length === phoneNumberSize) {
      setLoading(true);
      setErrorCredentials(false)
      setPhoneNumber(value)
      api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/checkMobileNumber`, {
        number: value,
        locationId: process.env.REACT_APP_LOCATION_ID,
        numberPrefix: selectedCountry.dial_code,
      })
        .then(function (response) {
          console.log(response);
          if (response?.data?.ResultCode === 1) {
            console.log(response?.data);
            setPhoneAlreadyInUse(false);
            if (selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0).toString() !== '9' || !numbers.includes(phoneNumber.charAt(1).toString()))) {
              setPhoneNumber('')
              setSelectedInput('phoneNumber')
              setErrorNumberPrefix(true)
            } else {
              setErrorNumberPrefix(false)
            }
            setError(false)
          } else if (response?.data?.Error === 'L23') {
            console.log(response?.data?.Description);
            setPhoneAlreadyInUse(true);
            if (selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0) !== '9' || !numbers.includes(phoneNumber.charAt(1)))) {
              setPhoneNumber('')
              setSelectedInput('phoneNumber')
              setErrorNumberPrefix(true)
            } else {
              setErrorNumberPrefix(false)
            }
            setError(false)
          } else if (response?.data?.Error === 'L25') {
            console.log(response?.data?.Description);
            setPhoneAlreadyInUse(true);
            setError(true)
          } else {
            console.log(response?.data);
            console.log('Unknow Error');
            setPhoneAlreadyInUse(false);
            setSelectedInput('phoneNumber');
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      setPhoneNumber(value)
    }
  }

  const navTo = (url, state) => {
    handleTransition(url, state)
  };

  const isPhoneNumberValid = () => {
    const pattern = new RegExp(`^\\d{${phoneNumberSize}}$`);

    if (phoneNumber.match(pattern)) {
      if (selectedCountry.name === 'Portugal' && (phoneNumber.charAt(0) !== '9' || !numbers.includes(phoneNumber.charAt(1)))) {
        // is invalid
        return false;
      } else {
        // is valid
        return true;
      }
    }
    return false
  };

  const isPinValid = () => {
    const pattern = new RegExp(`^\\d{${pinSize}}$`);
    if (pin.match(pattern)) {
      // is valid
      return true;
    }
  };

  const isConfirmPinValid = () => {
    const pattern = new RegExp(`^\\d{${pinSize}}$`);
    if (confirmPin.match(pattern) && confirmPin === pin) {
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if (phoneAlreadyInUse === false) {
      if (isPhoneNumberValid() && isPinValid() && isConfirmPinValid() && pin === confirmPin) {
        // input is valid
        return true;
      } else {
        // input is invalid
        return false;
      }
    } else {
      if (isPhoneNumberValid() && isPinValid()) {
        // input is valid
        return true;
      } else {
        // input is invalid
        return false;
      }
    }
  };

  const handleSubmit = () => {
    const { type, size, accessibility } = state;

    setLoading(true);
    setIsSubmitting(true);

    api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/assignLockerToUser`, {
      locationId: process.env.REACT_APP_LOCATION_ID,
      size: size,
      number: phoneNumber,
      pin: pin,
      name: phoneNumber,
      numberPrefix: selectedCountry.dial_code,
      type: type,
      accessibility: accessibility,
    })
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data);
          setErrorCredentials(false)
          navTo('/lokker-assigned', { state: { lockers: response?.data.Data, number: phoneNumber, pin: pin, numberPrefix: selectedCountry.dial_code } });
        }else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
          setPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber')
            setErrorCredentials(true)
            setPinAnimation('');
            setPin('');
          }, invalidInputAnimationDuration * 1000);
          setSelectedInput('pin');
        }else if (response?.data?.Description === 'No locker avaliable') {
          console.log(response?.data?.Description);
          navTo('/lokker-type', { state: { checked: true }});
        }else {
          console.log(response?.data);
          console.log('Unknow Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo('/');
  };

  useEffect(() => {
    if (confirmPin.length === pinSize && !isConfirmPinValid()) {
      setConfirmPinAnimation('shake');
      setTimeout(() => {
        setConfirmPinAnimation('');
        setConfirmPin('');
      }, invalidInputAnimationDuration * 1000);
    }
  }, [confirmPin]);

  const changePin = (value) => {
    setPin(value)
  }

  const changeConfirmPin = (value) => {
    setConfirmPin(value)
  }

  // logic to stop handleSubmit automatic when user press check key on android keyboard
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative">
      <LoadingModal isLoading={loading} />
      <Form>
        <Row
          style={{
            backgroundColor: colors['beije'].light,
            padding: '5vw 0 5vw 0'
          }}
          className="justify-content-center"
        >
          {selectedInput === 'phoneNumber' &&
            <>
              <Col xs="12" md="12" className="m-0">
                <SelectCountryPrefix
                  setPhoneNumberSize={setPhoneNumberSize}
                  setPhoneNumber={setPhoneNumber}
                  setSelectedInput={setSelectedInput}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                />
              </Col>
              <Col xs="10" md="8" className="m-0 d-flex justify-content-center">
                <FormGroup>
                  <Label for="phoneNumber" style={{ fontSize: '2.5vw', fontWeight: '600' }}>{t.messages['app.phoneNumber']}</Label>
                  <PinInput 
                    key={phoneNumberSize}
                    length={phoneNumberSize} 
                    focus 
                    type="numeric" 
                    inputMode="numeric" 
                    style={{padding: '10px'}}  
                    inputStyle={{ border: 'none', borderBottom: '1px solid black', borderRadius: 0, width: '20px' }}
                    onChange={handleNumberChangeMobile}
                  />
                </FormGroup>
              </Col>
            </>
          }
          {(selectedInput === 'pin' || selectedInput === 'confirmPin') &&
            <>
              <Col xs="8" className="m-0 d-flex justify-content-center pin-input">
                <FormGroup>
                  <Label for="pin" style={{ fontSize: '2.5vw', fontWeight: '600' }}>{t.messages['app.insertYourPin']}</Label>
                  <PinInput 
                    length={pinSize} 
                    secret
                    type="numeric" 
                    inputMode="numeric" 
                    style={{padding: '0px'}}  
                    inputStyle={{ border: 'none', borderBottom: '1px solid black', borderRadius: 0, width: '20px' }}
                    onChange={changePin}
                  />
                </FormGroup>
              </Col>
              <Col xs="8" className="m-0 d-flex justify-content-center pin-input">
                {phoneAlreadyInUse === false &&
                  <FormGroup>
                    <Label for="confirmPin" style={{ fontSize: '2.5vw', fontWeight: '600' }}>{t.messages['app.confirmPin']}</Label>
                    <PinInput 
                    length={pinSize} 
                    secret
                    type="numeric" 
                    inputMode="numeric" 
                    style={{padding: '0px'}}  
                    inputStyle={{ border: 'none', borderBottom: '1px solid black', borderRadius: 0, width: '20px' }}
                    onChange={changeConfirmPin}
                  />
                  </FormGroup>
                }
              </Col>
              <Col md="12" className="m-0 mt-2">
                <span
                  style={{ fontSize: '3vw', fontWeight: '600', textDecoration: 'underline' }}
                  onClick={() => (
                    setSelectedInput('phoneNumber'),
                    setPin('')
                  )}
                >
                  {t.messages['app.previous']}
                </span>
              </Col>
            </>
          }
          {error &&
            <Col md="12" className="m-0 mt-2">
              <span style={{ fontSize: '3vw', fontWeight: '700', color: colors.error }}>
                {t.messages['app.alreadyHaveLocker']}
              </span>
            </Col>
          }
          {errorNumberPrefix &&
            <Col md="12" className="m-0 mt-2">
              <span style={{ fontSize: '3vw', fontWeight: '700', color: colors.error }}>
                {t.messages['app.insertValidNumber']}
              </span>
            </Col>
          }
          {errorCredentials &&
            <Col md="12" className="m-0 mt-2">
              <span style={{ fontSize: '3vw', fontWeight: '700', color: colors.error }}>
                {t.messages['app.invalidCredentials']}
              </span>
            </Col>
          }
        </Row>
        <Row className="m-0 px-3">
          {selectedInput === 'phoneNumber' &&
            <PrimaryButton
              text={t.messages['app.next']}
              onClick={() => setSelectedInput('pin')}
              isEnabled={isPhoneNumberValid() && !isSubmitting && !error && !errorNumberPrefix}
              color="yellow"
              txtColor="light"
            />
          }
          {(selectedInput === 'pin' || selectedInput === 'confirmPin') &&
            <PrimaryButton
              text={t.messages['app.confirm']}
              onClick={isSubmitting ? () => null : handleSubmit}
              isEnabled={validateInput() && !isSubmitting && !error && !errorNumberPrefix}
              color="yellow"
              txtColor="light"
            />
          }
          <PrimaryButton
            text={t.messages['app.cancel']}
            onClick={goBack}
            color="white"
            outline={true}
            border
            noBackground
          />
        </Row>
      </Form>
    </TransitionView>
  )
}