import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { colors } from 'config/colors';

const styles = {
  container: {
    backgroundColor: colors.white,
    border: `1px solid ${colors['grey'].light}`,
    borderRadius: '6px',
    paddingTop: '3.5vw'
  },
  headerText: {
    fontSize: '2.5vw',
    fontWeight: '600'
  }, 
  paragraphsText: {
    fontSize: '2.5vw',
    fontWeight: '300'
  },
  footerText: {
    fontSize: '2vw'
  },
  phoneNumberTxt: {
    fontSize: '2.5vw',
    fontWeight: '300',
    padding: '0 6px 0 6px'
  }
}

export const DeliveryConfirmMessageBox = ({ clientPhoneNumber, lokkerSize }) => {
 const t = useIntl();

 const clientPhoneNumberSplited = clientPhoneNumber.toString().match(/\d{1,3}/g);

  return (
    <div style={styles.container}>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.headerText}>
            <FormattedMessage id="app.receiverPhoneNumber" />:
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          {clientPhoneNumberSplited.map((item, index) => 
            <span style={styles.phoneNumberTxt} key={index}>
              {item}
            </span>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.headerText}>
            <FormattedMessage id="app.lokkerLabel" />:
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.paragraphsText}>
            {lokkerSize === 'S' && t.messages['app.sizeS']}
            {lokkerSize === 'M' && t.messages['app.sizeM']}
            {lokkerSize === 'L' && t.messages['app.sizeL']}
          </span>
        </Col>
      </Row>

      <Row className="justify-content-center py-4">
        <Col xs="10">
          <span style={styles.footerText}>
            <FormattedMessage id="app.depositConfirmTxt" />
          </span>
        </Col>
      </Row>
      
    </div>
  );
};