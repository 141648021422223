import React, { useEffect, useState } from 'react';
import { TopNavMobile } from 'components/layout/TopNavMobile';
import ExpiredTokenMessage from 'components/common/ExpiredTokenMessage';
import InvalidTokenMessage from 'components/common/InvalidTokenMessage';
import { colors } from 'config/colors';
import { useNavigate, useLocation } from 'react-router';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { ReactComponent as Background } from 'assets/images/background/background.svg';
import { backgroundImageExclusions } from 'config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import Socket from 'helpers/socket';
import { checkToken } from "redux/actions/token"
import { LoadingModal } from 'components/common/LoadingModal';
import { routes } from 'config/appConfig';
import { ReactComponent as PoweredByLokk } from 'assets/images/footer/powered.svg'
import { FaqsModal } from 'components/common/FaqsModal';
import { ReactComponent as Help } from 'assets/images/icons/v2/Help.svg';

export const AppLayout = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation();
  const zone = pathname.split('/')[1]
  const credentials = useSelector(state => state.credentials);
  const layout = useSelector(state => state.layout);

  const [delay, setDelay] = useState(false)
  const [faqOpen, setFaqOpen] = useState(false);

  const [loading, setLoading] = useState(false)
  const [expired, setExpired] = useState(false)
  const [invalid, setInvalid] = useState(false)

  let navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);

  var timer = null;

  const refreshUrlToken = (data) => {
    dispatch({type: 'REFRESH_TOKEN', payload: data })
  };

  // event listeners to detect user interaction with the screen
  const detectActivity = () => {
    document.addEventListener('mousedown', resetInactivityTimer);
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('touchstart', resetInactivityTimer);
    document.addEventListener('scroll', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
  };
  
  // set the inactivity timer to navigate to the home view after x seconds have passed
  const resetInactivityTimer = () => {
    let delay = 120;
    setDelay(false)
    routes.map((item)=>{
      if(zone === item.path){
        delay = item.inativityTime;
        setDelay(delay)
      }
    })
    
    if(timer !== null) clearTimeout(timer);
    
    timer = setTimeout(() => {
      
    }, delay * 1000);

    // TODO: clear any stored user data
  };


  //initiate the inactivity detection on component mount
  useEffect(() => {
    resetInactivityTimer();
    detectActivity();
  },[credentials.urlToken, zone]);


  useEffect(() => {
    setLoading(true)
    if(credentials.urlToken){
      dispatch(checkToken(credentials.urlToken))
      .then(res => {
        if (res.data.ResultCode === 1) {
          setExpired(false);
          setInvalid(false);
          setLoading(false)
        }
      }).catch((err) => {
        if(err.data.Error === 'L27'){
          setInvalid(true)
          setExpired(false);
          setLoading(false)
        }else if(err.data.Error === 'L28'){
          setExpired(true);
          setInvalid(false)
          setLoading(false)
        }else{
          setInvalid(true)
          setExpired(false);
          setLoading(false)
        }
      });
    }
  },[credentials.urlToken,pathname]);

  return (
    <div style={{ overflow: 'hidden',height: `90vh`}}>
      <Socket handleLockerStatusUpdate={refreshUrlToken} token={process.env.REACT_APP_API_TOKEN}/>
      <LoadingModal isLoading={loading} />
      <div 
        style={{ 
          height: '15%',
        }}
        >
        <TopNavMobile counter={delay}/>
      </div>
      <main
        style={{ 
          height: '75%'
        }}
      >
        {!backgroundImageExclusions.includes(zone) && !expired && !invalid &&
          <Background 
            fill={!layout.homepage ? colors[process.env.REACT_APP_ZONE].light : colors['beije'].light}
            width="100%" 
            height="100%" 
            style={{ 
              position: 'absolute', 
              top: '20%', 
              right: '0', 
              zIndex: -1 ,
            }} 
          />
        }
        {expired && <ExpiredTokenMessage />}
        {invalid && <InvalidTokenMessage />}
        
        {!loading && !expired && !invalid &&
          <div className="h-100" style={{ position: 'relative', top: '0',  right: '0', zIndex: 1 }}>
            {children}
          </div>
        }
      </main>
      <footer
        className="d-flex align-items-center justify-content-between px-5"
        id="teste"
        style={{ 
          height: '10%',
          zIndex:9 
        }}
      >
        <span onClick={() => setFaqOpen(true)}>
          <Help height="20" width="20" />
        </span>
        <PoweredByLokk width="100" className="powered-by-lokk-logo" fill={colors.darkBlue}/>
      </footer>
      <FaqsModal isOpen={faqOpen} setIsOpen={setFaqOpen} />
    </div>
  );
};