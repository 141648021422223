import React from 'react';

export const SquareIconButton = ({ icon, text, onClick, size }) => {

  const styles = {
    button: {
      height: size ? size : '150px',
      width: size ? size : '150px',
      backgroundColor: 'transparent',
      borderRadius: '20px',
      border: `none`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 20px',
    },
    text: {
      fontSize: '2.5vw'
    }
  }

  return(
    <button style={styles.button} onClick={onClick}>
      <span>{icon}</span>
    </button>
  )
}