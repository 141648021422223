import { 
  routes
} from 'config/appConfig';
import { useSelector } from 'react-redux';

export const GetTopBarLabel = ( path )  => {
  const layout = useSelector(state => state.layout);
  let label = 'app.header';

  let pathArr = path.split('/');

  let pathToUse = false;

  const checkUrlContainsToken = () => {
    let result = false;

    if (routes.some(e => e.path === pathArr[2])) {
      result = false
    }else{
      result = true
    }
    return result;
  }

  if(layout.homepage && checkUrlContainsToken()){
    pathToUse = process.env.REACT_APP_ZONE;
  }else{
    pathToUse = pathArr[1];
  }

  routes.forEach((key) => {
    if(key.path === pathToUse){
      label = key.label;
    }
  });
  
  return label;
  
};

export const GlobalDebug = (function () {
  var savedConsole = console;
  /**
  * @param {boolean} debugOn
  * @param {boolean} suppressAll
  */
  return function (debugOn, suppressAll) {
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

