import { ReactComponent as FlagPortugal } from 'assets/images/icons/v2/FlagPortugal.svg';
import { ReactComponent as FlagSpain } from 'assets/images/icons/v2/FlagSpain.svg';
import { ReactComponent as FlagFrance } from 'assets/images/icons/v2/FlagFrance.svg';
import { ReactComponent as FlagUK } from 'assets/images/icons/v2/FlagUK.svg';
import { ReactComponent as FlagBrazil } from 'assets/images/icons/v2/FlagBrazil.svg';

const flagSize= '30px';

//First Element on array will be the country default value

export const countryCodes = [
  {
    name: "Portugal",
    characters: 9,
    dial_code: "+351",
    code: "PT",
    flag: <FlagPortugal width={flagSize} height={flagSize} />
  },
  {
    name: "France",
    characters: 9,
    dial_code: "+33",
    code: "FR",
    flag: <FlagFrance width={flagSize} height={flagSize} />
  },
  {
    name: "Spain",
    characters: 9,
    dial_code: "+34",
    code: "ES",
    flag: <FlagSpain width={flagSize} height={flagSize} />
  },
  {
    name: "United Kingdom",
    characters: 10,
    dial_code: "+44",
    code: "GB",
    flag: <FlagUK width={flagSize} height={flagSize}  />
  },
  {
    name: "Brazil",
    characters: 11,
    dial_code: "+55",
    code: "BR",
    flag: <FlagBrazil width={flagSize} height={flagSize}  />
  },
]