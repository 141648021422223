import React from 'react';
import { Row, Col } from 'reactstrap'
import { useIntl } from 'react-intl';

const ExpiredTokenMessage = () => {
  const t = useIntl();
  return (
    <Row className="h-100">
      <Col xs="12" className="px-5 d-flex align-items-center">
        <span>{t.messages['app.errorExpiredToken']}</span>
      </Col>
    </Row>
  )
}

export default ExpiredTokenMessage;