import React from 'react';
import { Portal }  from 'helpers/Portal';
import { colors } from 'config/colors';
import { Row, Col, Card } from 'reactstrap';
import { ReactComponent as FlagPortugal } from 'assets/images/icons/v2/FlagPortugal.svg';
import { ReactComponent as FlagSpain } from 'assets/images/icons/v2/FlagSpain.svg';
import { ReactComponent as FlagUK } from 'assets/images/icons/v2/FlagUK.svg';
import { LanguageModalButton } from 'components/common/LanguageModalButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from 'components/common/PrimaryButton';

const styles = {
  container: {
    backgroundColor: `${colors.white}`,
    borderRadius: '0vw',
    border: 'none'
  },
  text: {
    fontSize: '3.5vw',
    letterSpacing: '0.25vw',
    fontWeight: '600'
  }
}

export const LanguageModal = ({ isOpen, setIsOpen }) => {
  const t = useIntl();
  const dispatch = useDispatch();

  const changeLanguage = (lang) => {
    dispatch({type: 'CHANGE_LANGUAGE', payload: lang})
    setIsOpen(false);
  }

  return(
    <Portal isOpen={isOpen}>
      <Card style={styles.container} className="h-100 justify-content-center p-4 px-2 py-5">
        <Row className="d-flex justify-content-center align-items-between">
          <Col xs="12" className="d-flex align-items-center justify-content-center px-4">
            <span style={styles.text}>
              <FormattedMessage id="app.choose-language" />
            </span>
          </Col>
        </Row>
        <Row className="m-0">
            <Col xs="12">
              <LanguageModalButton icon={<FlagPortugal width="60px" />} text={t.messages['app.language-portuguese']} onClick={() => changeLanguage('pt-PT')}/>
              <LanguageModalButton icon={<FlagSpain width="60px" />} text={t.messages['app.language-spanish']} onClick={() => changeLanguage('es-ES')}/>
              <LanguageModalButton icon={<FlagUK width="60px"  />} text={t.messages['app.language-english']} onClick={() => changeLanguage('en-US')}/>
            </Col>
          </Row>
        <Row className="text-center m-0">
          <Col xs="12">
            <PrimaryButton
              text={t.messages['app.goBack']}
              onClick={() => setIsOpen(false)}
              color="white"
              outline={true}
              border="lightGrey"
              txtColor="light"
            />
          </Col>
        </Row>
      </Card>
    </Portal>
  );
};
