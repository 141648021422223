import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { ReactComponent as WarningIcon } from 'assets/images/icons/v1/WarningIcon.svg';

const styles = {
  text: {
    fontSize: '3.5vw',
    fontWeight: '600'
  }
}

export const NotFoundView = () => {
  return(
    <Row className="display-flex flex-row h-100 align-items-center">
      <Col xs="3">
        <WarningIcon />
      </Col>
      <Col xs="9">
        <span style={styles.text}>
          <FormattedMessage id="route.notFound" />
        </span>
      </Col>
    </Row>
  );
};