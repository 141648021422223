import React from 'react';
import ReactDom from 'react-dom';
import { colors } from 'config/colors';

const styles = {
  container: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '99',
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.white,
  }
}

export const Portal = ({ children, isOpen }) => {

  if (isOpen === false) return null;

  return ReactDom.createPortal(
    <div style={styles.container}>
      {children}
    </div>,
    document.getElementById('portal')
  )
}