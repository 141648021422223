import React, { useState, useEffect } from 'react';
import { Portal } from 'helpers/Portal';
import { colors } from 'config/colors';
import {
  Row,
  Col,
  Card,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { ReactComponent as FlagPortugal } from 'assets/images/icons/v2/FlagPortugal.svg';
import { ReactComponent as FlagSpain } from 'assets/images/icons/v2/FlagSpain.svg';
import { ReactComponent as FlagUK } from 'assets/images/icons/v2/FlagUK.svg';
import { ChevronDown } from 'react-feather';
import { getFaqs } from "redux/actions/faqs"

export const FaqsModal = ({ isOpen, setIsOpen }) => {
  const t = useIntl();
  const dispatch = useDispatch();
  const faqs = useSelector(state => state.faqs);
  const language = useSelector(state => state.language);

  const [open, setOpen] = useState('');

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const changeLanguage = (lang) => {
    dispatch({ type: 'CHANGE_LANGUAGE', payload: lang })
  }

  useEffect(() => {
    dispatch(getFaqs())
  },[isOpen]);

  return (
    <Portal isOpen={isOpen}>
      <Card className="h-100 bg-white px-4 overflow-auto py-5">
        <Row className="d-flex justify-content-center align-items-center h-25">
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <span style={{ fontSize: '4vw', letterSpacing: '0.25vw', fontWeight: '600'}}>
              <FormattedMessage id="app.faqs" />
            </span>
          </Col>
          <Col xs="12">
            <Row className="d-flex justify-content-center align-items-between">
              <Col xs="4" className="d-flex align-items-center justify-content-center">
                <FlagPortugal style={{width:"60px"}} onClick={() => changeLanguage('pt-PT')} />
              </Col>
              <Col xs="4" className="d-flex align-items-center justify-content-center">
                <FlagSpain style={{width:"60px"}} onClick={() => changeLanguage('es-ES')} />
              </Col>
              <Col xs="4" className="d-flex align-items-center justify-content-center">
                <FlagUK style={{width:"60px"}} onClick={() => changeLanguage('en-US')} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-0">
          <Col xs="12" className="align-items-center justify-content-center py-3">
            <Accordion flush open={open} toggle={toggle} className="w-100">
              {faqs && Object.keys(faqs).map((key, index) => 
                <AccordionItem key={index}>
                  <AccordionHeader targetId={faqs[key].id.toString()} >
                    <Col xs="11">
                      <span style={{ fontSize: '3.5vw', fontWeight: '600',transition: '0.2s all', color: faqs[key].id.toString() === open && colors[process.env.REACT_APP_ZONE].medium }}>
                        {faqs[key].title[`${language}`]}
                      </span>
                    </Col>
                    <Col xs="1">
                      <ChevronDown size="25px" style={{ transform: faqs[key].id.toString() === open && 'rotate(-180deg)', transition: '0.2s all', color: faqs[key].id.toString() === open && colors[process.env.REACT_APP_ZONE].medium}}/>
                    </Col>
                  </AccordionHeader>
                  <AccordionBody accordionId={faqs[key].id.toString()}>
                    <span style={{ fontSize: '3vw', textAlign: 'justify', textJustify: 'inter-word' }}>
                      <div dangerouslySetInnerHTML={{ __html: faqs[key].text[`${language}`] }} />
                    </span>
                  </AccordionBody>
                </AccordionItem>
              )}
            </Accordion>
          </Col>
        </Row>
        <Row className="text-center d-flex align-items-center m-0 pb-5">
          <Col xs="12">
            <PrimaryButton
              text={t.messages['app.goBack']}
              onClick={() => setIsOpen(false)}
              color="white"
              outline={true}
              border="none"
              txtColor="light"
            />
          </Col>
        </Row>
      </Card>
    </Portal>
  );
};