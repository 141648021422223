import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Label } from "reactstrap";
import { useNavigate } from "react-router";
import { TransitionView } from "components/common/transition-view";
import { useRouterTransition } from "hooks/useRouterTransition";
import { useIntl } from "react-intl";
import { PrimaryButton } from "components/common/PrimaryButton";
import api from "helpers/api";
import { LoadingModal } from "components/common/LoadingModal";
import { colors } from "config/colors";
import SelectCountryPrefix from "components/common/SelectCountryPrefix";
import { countryCodes } from "config/numberPrefix";
import PinInput from "react-pin-input";

export const RedZoneAccessMyLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const [selectedInput, setSelectedInput] = useState("phoneNumber");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmPinAnimation, setConfirmPinAnimation] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumberSize, setPhoneNumberSize] = useState(
    selectedCountry.characters
  );
  const [errorCredentials, setErrorCredentials] = useState(false);

  const invalidInputAnimationDuration = 0.7;

  const pinSize = 4;

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if (val === "#") setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if (phoneNumber.length === phoneNumberSize - 1) {
      setPhoneNumber(phoneNumber + val);
      setSelectedInput("pin");
    } else if (phoneNumber.length < phoneNumberSize)
      setPhoneNumber(phoneNumber + val);
    else if (phoneNumber.length === phoneNumberSize) setSelectedInput("pin");
  };

  const handleChangePin = (val) => {
    // delete last char from pin
    if (val === "#") {
      if (pin.length === 0) setSelectedInput("phoneNumber");
      else setPin(pin.slice(0, -1));
    }
    // add char to pin
    // if last char also go to next input
    else if (pin.length === pinSize - 1) {
      setPin(pin + val);
    } else if (pin.length < pinSize) setPin(pin + val);
  };

  const navTo = (url, state) => {
    handleTransition(url, state);
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if (val === "*") {
      setPhoneNumber("");
      setPin("");
      setSelectedInput("phoneNumber");
    } else {
      // handle specific input
      switch (selectedInput) {
        case "phoneNumber":
          handleChangePhoneNumber(val);
          break;
        case "pin":
          handleChangePin(val);
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    const pattern = new RegExp(`^\\d{${phoneNumberSize}}$`);
    if (phoneNumber.match(pattern)) {
      // is valid
      return true;
    }
  };

  const isPinValid = () => {
    const pattern = new RegExp(`^\\d{${pinSize}}$`);
    if (pin.match(pattern)) {
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if (isPhoneNumberValid() && isPinValid()) {
      // input is valid
      return true;
    } else {
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setIsSubmitting(true);
    api
      .get(
        `${process.env.REACT_APP_API_URL}api/mar-shopping/getLockersByNumber?locationId=${process.env.REACT_APP_LOCATION_ID}&pin=${pin}&number=${phoneNumber}&numberPrefix=${selectedCountry.dial_code}`
      )
      .then(function (response) {
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data?.Lockers);
          setErrorCredentials(false);
          navTo("/my-lokkers", {
            state: {
              lockers: response?.data?.Lockers,
              phoneNumber: phoneNumber,
              pin: pin,
              numberPrefix: selectedCountry.dial_code,
            },
          });
        } else if (response?.data?.Description === "Invalid PIN") {
          console.log(response?.data?.Description);
          setConfirmPinAnimation("shake");
          setErrorCredentials(true);
          setTimeout(() => {
            setSelectedInput("phoneNumber");
            setConfirmPinAnimation("");
          }, invalidInputAnimationDuration * 1000);
        } else {
          console.log("Unknow Error");
          setErrorCredentials(true);
          setConfirmPinAnimation("shake");
          setTimeout(() => {
            setSelectedInput("phoneNumber");
            setConfirmPinAnimation("");
          }, invalidInputAnimationDuration * 1000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setErrorCredentials(true);
        setConfirmPinAnimation("shake");
        setTimeout(() => {
          setSelectedInput("phoneNumber");
          setConfirmPinAnimation("");
        }, invalidInputAnimationDuration * 1000);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo("/");
  };

  useEffect(() => {
    setPin("");
  }, [selectedCountry]);

  return (
    <TransitionView
      animation="fade-down"
      transitionType={transitionType}
      className="position-relative justify-content-start"
    >
      <LoadingModal isLoading={loading} />
      <Form>
        <Row
          style={{
            backgroundColor: colors["beije"].light,
            padding: "3vw 0 2vw 0",
          }}
        >
          <Col xs="12" md="12" className="m-0">
            <SelectCountryPrefix
              setPhoneNumberSize={setPhoneNumberSize}
              setPhoneNumber={setPhoneNumber}
              setSelectedInput={setSelectedInput}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </Col>
          <Row className="m-0 justify-content-center">
            <Col xs="10" md="8">
              <Label
                for="phoneNumber"
                style={{ fontSize: "2.5vw", fontWeight: "600" }}
              >
                {t.messages["app.phoneNumber"]}
              </Label>
              <PinInput
                key={phoneNumberSize}
                length={phoneNumberSize}
                initialValue={phoneNumber}
                type="numeric"
                inputMode="numeric"
                style={{ padding: "10px", height: "0 !important" }}
                inputStyle={{
                  border: "none",
                  borderBottom: "1px solid black",
                  borderRadius: 0,
                  width: "20px",
                  height: "10px !important",
                }}
                onChange={(value) => setPhoneNumber(value)}
              />
            </Col>
            <Col xs="8" md="8">
              <Label for="pin" style={{ fontSize: "2.5vw", fontWeight: "600" }}>
                {t.messages["app.insertYourPin"]}
              </Label>
              <PinInput
                value={pin}
                length={pinSize}
                secret
                type="numeric"
                inputMode="numeric"
                style={{ padding: "0px" }}
                inputStyle={{
                  border: "none",
                  borderBottom: "1px solid black",
                  borderRadius: 0,
                  width: "20px",
                }}
                onChange={(value) => setPin(value)}
              />
            </Col>
          </Row>
          <Col md="12" className="m-0 mt-2">
            <span
              style={{ fontSize: "2.5vw", textDecoration: "underline" }}
              onClick={() => navTo("/recover-pin")}
            >
              {t.messages["app.forgotPin"]}
            </span>
          </Col>
          {errorCredentials && (
            <Col md="12" className="m-0 mt-2">
              <span
                style={{
                  fontSize: "3vw",
                  fontWeight: "700",
                  color: colors.error,
                }}
              >
                {t.messages["app.invalidCredentials"]}
              </span>
            </Col>
          )}
        </Row>
        <Row className="m-0 px-3">
          <PrimaryButton
            text={t.messages["app.confirm"]}
            onClick={handleSubmit}
            isEnabled={validateInput()}
            color="yellow"
            txtColor="light"
          />
          <PrimaryButton
            text={t.messages["app.cancel"]}
            onClick={goBack}
            color="white"
            border
            noBackground
          />
        </Row>
      </Form>
    </TransitionView>
  );
};
