import React from 'react'
import Websocket from 'react-websocket'

class Socket extends React.Component {
  handleWsOpen = () => {
    this.refWebSocket.sendMessage("{\"token\":\""+this.props.token+"\"}");
  }

  handleWsMessage = (evt) => {
    if(evt.charAt(0) === '{'){
      const parsedMsg = JSON.parse(evt)
      console.log(parsedMsg)
      if(parsedMsg.MsgType === "QrCodeUpdate"){
        this.props.handleLockerStatusUpdate(parsedMsg.Data)
      }
    }
  }

  render(){
    return(
      <Websocket
        url={process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token='+this.props.token}
        onOpen={this.handleWsOpen}
        onMessage={this.handleWsMessage}
        reconnect={true}
        debug={true}
        ref={Websocket => {
          this.refWebSocket = Websocket;
        }}
      />
    )
  }
}

export default Socket
