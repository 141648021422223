import { useState } from 'react';
import { routerTransitionDelay } from 'config/appConfig';
import { useSelector, useDispatch } from 'react-redux';

export const useRouterTransition = (navigate) => {
  const dispatch = useDispatch()
  const [transitionType, setTransitionType] = useState('in');
  const credentials = useSelector(state => state.credentials);


  const handleTransition = (path, state) => {

    //setTransitionType('out');

    setTimeout(() => {
      if(path !== '/'){
        navigate(path, state)
        dispatch({type: 'SET_HOMEPAGE', payload: false})
      }else{
        navigate('/' + credentials.urlToken, state)
        dispatch({type: 'SET_HOMEPAGE', payload: true})
      }
    }, routerTransitionDelay * 300);
  }

  return { transitionType, handleTransition };
};
