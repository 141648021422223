import React from 'react';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { ReactComponent as Depositar } from 'assets/images/icons/v1/Depositar.svg';
import { ReactComponent as Levantar } from 'assets/images/icons/v1/Levantar.svg';
import { SecondaryNav } from 'components/common/SecondaryNav';

export const RedZonePickNCollect = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };
  
  return(
    <TransitionView animation="fade-down" transitionType={transitionType}>
      <SecondaryNav  secNavText="app.chooseAnAction" navToURL="/" />

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.pickLabel']}
          subtext={t.messages['app.pickSubLabel']}
          icon={<Depositar height="40" width="40"/>}
          onClick={() => navTo('/deposit-size')}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.collectLabel']}
          subtext={t.messages['app.collectSubLabel']}
          icon={<Levantar height="40" width="40"/>}
          onClick={() => navTo('/collect-access-pin')}
        />
      </Row>
    </TransitionView>
  );
};