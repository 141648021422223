import api from "helpers/api"

export const refreshToken = ( token ) => {
  return {
    type : 'REFRESH_TOKEN',
    payload : token
  };
};

export const checkToken = (token = '') => (dispatch) =>
new Promise(function(resolve, reject){
    api.post(`api/mar-shopping/checkToken`, {
      token: token,
    })
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        resolve(response)
      } else {
        reject(response)
        throw(response);
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})

export const setUrlToken = ( urlToken ) => {
  return {
    type : 'SET_URL_TOKEN',
    payload : urlToken
  };
};

export const setToken = ( token ) => {
  return {
    type : 'SET_TOKEN',
    payload : token
  };
};
